.main-footer {
    position: fixed;
    bottom:0px;
    width:100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.bg-info-icon {
    border-radius: 2.rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 2.0rem;
    -ms-flex-pack: center;
    justify-content: center;
    width: 70px;
    margin: 0 20px 0 0;
}
    
